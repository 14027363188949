









































































import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			item: "",
			size: "",
			price: 0,
			quantity: 0,
			shopping: 0,
			url: "",
			date: null
		};
	},
	mounted() {
		(this.$refs.startInput as HTMLDivElement).focus();
		addEventListener("keydown", e => {
			if (e.keyCode == 27) this.$emit("isUpdated", false);
		});
	},
	methods: {
		create() {
			this.$store.commit("pushLoading", {
				name: "BUDGET",
				message: "동아리 예산 생성 중"
			});
			this.$store
				.dispatch("BUDGET", {
					item: this.item,
					size: this.size,
					price: this.price,
					quantity: this.quantity,
					shopping: this.shopping,
					url: this.url,
					date: this.date
				})
				.then(award => {
					this.$store.commit("clearLoading", "BUDGET");
					this.$emit("isUpdated", false);
				})
				.catch(err => {});
		}
	},
	computed: {}
});
