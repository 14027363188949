



















import Vue from "vue";
import BudgetTableBox from "../../components/Page/BudgetTableBox.vue";
import BudgetBoxCreatePopup from "../../components/Page/BudgetBoxCreatePopup.vue";
export default Vue.extend({
	components: {
		BudgetTableBox,
		BudgetBoxCreatePopup
	},
	data() {
		return {
			budgets: [] as any[],
			isShowBudgetCreatePopup: false,
			showContextMenu: false,
			currentId: ""
		};
	},
	created() {
		this.reload();
	},
	methods: {
		reload() {
			this.isShowBudgetCreatePopup = false;
			this.$store.commit("pushPageLoading", {
				name: "GET_CLUB_BUDGETS",
				message: "동아리 예산 불러오는 중"
			});
			this.$store
				.dispatch("GET_CLUB_BUDGETS")
				.then(budgets => {
					this.$store.commit("clearPageLoading", "GET_CLUB_BUDGETS");

					this.budgets = budgets.map((data: any) => {
						data.total = data.price * data.quantity + data.shopping;
						return data;
					});
				})
				.catch(err => {});
		},
		createContextMenu(e: any) {
			e.e.preventDefault();
			if (this.isCreateAble) {
				this.showContextMenu = true;
				this.currentId = e.id;
				this.$nextTick(() => {
					let contextmenu: any = this.$refs.contextmenu;
					contextmenu.style.left = e.e.x + "px";
					contextmenu.style.top = e.e.y + "px";
				});
			}
		},
		remove() {
			if (this.currentId) {
				this.$store.commit("pushPageLoading", {
					name: "BUDGET_DELETE",
					message: "동아리 예산 삭제 중"
				});
				this.$store
					.dispatch("BUDGET_DELETE", { _id: this.currentId })
					.then(award => {
						this.$store.commit("clearPageLoading", "BUDGET_DELETE");
						this.reload();
					})
					.catch(err => {});
			}
		}
	},
	computed: {
		isCreateAble() {
			if (this.$store.state.club.ranks) {
				let user = this.$store.state.club.members.find((member: any) => {
					return member.user == this.$store.state.userInformation._id;
				});
				if (user)
					return (
						this.$store.state.club.ranks.find((rank: any) => rank.id == user.rank).isAdmin ||
						this.$store.state.club.ranks.find((rank: any) => rank.id == user.rank).permission.indexOf(21) != -1
					);
				else return false;
			} else return false;
		}
	}
});
