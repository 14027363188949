












































import Vue from "vue";
import BudgetTableItem from "./BudgetTableItem.vue";
export default Vue.extend({
	components: {
		BudgetTableItem
	},
	props: {
		budgets: Array
	},
	data() {
		return {
			sortKey: "",
			sortBy: false
		};
	},
	methods: {
		orderBy(key: string) {
			if (this.sortKey == key) {
				this.sortBy = !this.sortBy;
			} else {
				this.sortKey = key;
			}
		}
	},
	computed: {
		getOrderedBudgets(): any {
			if (this.sortKey == "") {
				return this.budgets;
			} else {
				return this.budgets.sort((a: any, b: any): any => {
					if (this.sortBy) return b[this.sortKey] > a[this.sortKey] ? 1 : -1;
					else return a[this.sortKey] > b[this.sortKey] ? 1 : -1;
				});
			}
		}
	}
});
