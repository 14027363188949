





















import Vue from "vue";
export default Vue.extend({
	props: {
		data: Object
	},
	computed: {
		getDate() {
			return new Date(this.data.date).toISOString().slice(0, 10);
		}
	}
});
